/*eslint-disable*/
import './plugins/bootstrap-vue.js';
import '@/styles/index.scss';
import '@/styles/fonts.scss';
import PrismicVue from '@prismicio/vue';
import Vue from 'vue';
import router from './router';
import App from './App.vue';
import linkResolver from './link-resolver';
import store from './store';
import moment from 'vue-moment';
import VueMeta from 'vue-meta'


Vue.config.productionTip = false;
const accessToken = 'MC5YNFFaNEJFQUFDY0FGZU94.Su-_vXdHMQ3vv73vv73vv70sH--_vXdbB3pqVlLvv73vv73vv73vv71k77-977-9RDTvv73vv73vv73vv70';

// Add this before the new Vue instance
Vue.use(PrismicVue, {
  endpoint: window.prismic.endpoint,
  linkResolver,
  apiOptions: { accessToken },
});

Vue.use(moment);

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
