<template lang="pug">
  div(v-if="!seriesList" class="loading-overlay")
    <spinner-icon />
  .series-container(v-else)
    .swiper-controls(class="d-flex")
      //- .swiper-pagination
      .swiper-buttons(class="ml-auto")
        .swiper-button-prev
          chevron-icon(class="rotate-left")
        .swiper-button-next
          chevron-icon
    swiper(:options="swiperOptions")
      swiper-slide.series(v-for="series in seriesList" class="d-flex flex-wrap")
        .series-img(:style="`backgroundImage:url(${series.data.main_image})`")
        .series-info
          h2 {{ parseRichText(series.data.title) }}
          p {{ series.data.description }}
          swiper(class="d-flex" :options="issueSwiperOptions")
            swiper-slide.issue-container(v-for="item in series.data.issues")
              router-link(:to="{ name: 'comic', params: {comicId: item.issue.uid, \
                series: series.uid }}")
                  .issue(class="d-flex flex-wrap")
                    strong(class="mb-1") {{ item.issue_title }}
                    .thumbnail(class="mb-1" :style="`backgroundImage:url('${item.cover_image}')`")
                    | {{ item.comic_title }}
</template>

<script>
/* eslint-disable */
import PrismicDom from 'prismic-dom';
import { mapActions } from 'vuex';
import spinnerIcon from '@/assets/asterisk-light.svg';
import chevronIcon from '@/assets/chevron-circle.svg';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'App',
  components: {
    Swiper,
    SwiperSlide,
    spinnerIcon,
    chevronIcon,
  },
  data() {
    return {
      seriesList: null,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
         navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      issueSwiperOptions:{
         spaceBetween: 40,
         breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 'auto',
            spaceBetween: 40,
          }
        }
      }
    };
  },
  methods: {
    ...mapActions({
      setComics: 'setComics',
    }),
    parseRichText(text) {
      return this.$prismic.richTextAsPlain(text)
    },
    getContent () {
      this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'series'),
        {
          pageSize : 10,
          orderings : '[document.first_publication_date desc]'
        }
      ).then((response) => {
        this.seriesList = response.results;
      });
    },
  },
  created () {
    this.getContent();
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.series-container{
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  border-radius: 12px;
}
.series{
  margin-bottom: 12px;
  background: #222;
  overflow: hidden;
}
.series-img{
  width: 100%;
  min-height: 300px;
  background: #777;
  background-position: center center;
  background-size: cover;
  position: relative;
  @include md{
    min-height: 400px;
  }
  @include lg{
    min-height: 600px;
  }

  &:after{
    content: '';
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image:linear-gradient( rgba(0,0,0,0), #222 90%);
  }
}
.series-info{
  padding: 24px;
  width: 100%;
  text-align: left;
  h2, p{
    max-width: 800px;
  }

}
.issue-container{
  max-width: 200px;
  display: flex;
  justify-content: center;
}
.issues{

}
.issue{
  word-break: break-all;
  .thumbnail{
    width: 100%;

    height: 300px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
.swiper-controls{
  margin-bottom: 12px;
  padding: 0 12px;
  @include lg{
    padding: 0;
  }
}
.swiper-buttons{

}
/deep/ .swiper-button-next, .swiper-button-prev{
  position: relative;
  display: inline-block;
  top: auto;
  width: auto;
  height: auto;
  left: auto;
  right: auto;
  margin: auto;
  &::after {
    content: none;
  }
  svg{
    width: 20px;
    height: 20px;
    &.rotate-left{
      transform: rotate(180deg);
    }
    path{
      fill: #fff;
    }
  }
}
/deep/ .swiper-button-next{
  margin-left: 8px;
}

</style>
