<template lang="pug">
  div(v-if="!results" class="loading-overlay")
    <spinner-icon />
  .posts(v-else class="py-3")
    .featured-post(v-if="featuredPost")
      .thumbnail(:style="`backgroundImage:url('${featuredPost.data.thumbnail.url}')`")
      .details
        div
          span FEATURED
          .date {{ featuredPost.first_publication_date | moment("from", "now") }}
          router-link(:to="{ name: 'posts', params: { uid: featuredPost.uid, \
          title: $prismic.richTextAsPlain(featuredPost.data.title) }}")
            h1 {{ $prismic.richTextAsPlain(featuredPost.data.title) }}
            p {{ $prismic.richTextAsPlain(featuredPost.data.content) }}
          .tags
            .tag(@click="filterTag(tag)" v-for="tag in featuredPost.tags") {{ tag }}
    .post(v-for="post in results" :key="post.uid")
      .thumbnail(:style="`backgroundImage:url('${post.data.thumbnail.url}')`")
      .details
        .date {{ post.first_publication_date | moment("from", "now") }}
        router-link(:to="{ name: 'posts', \
          params: { uid: post.uid, title: $prismic.richTextAsPlain(post.data.title) }}")
          h1 {{ $prismic.richTextAsPlain(post.data.title) }}
          p {{ $prismic.richTextAsPlain(post.data.content) }}
        .tags
          .tag(@click="filterTag(tag)" v-for="tag in post.tags") {{ tag }}
    .pagination(class="my-5" v-if="totalPostSize")
      b-pagination( v-model="currentPage"
        @change="handlePagination"
        :total-rows="totalPostSize"
        :per-page="perPage")
</template>

<script>
/* eslint-disable */
import PrismicDom from 'prismic-dom';
import spinnerIcon from '@/assets/asterisk-light.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    spinnerIcon
  },
  data() {
    return {
      results: null,
      perPage: 10,
      currentPage: 1,
      totalPostSize: null,
      metaImage: null,
    };
  },
  mounted () {
    this.getContent();
  },
  watch:{
    $route(newVal){
      if(newVal){
        this.getContent();
      }
    }
  },
  computed: {
    ...mapGetters({
      featuredPost: 'featuredPost',
    }),
  },
  methods: {
    async getContent () {
      let query = null;
      this.results = null;

      if(this.$route.params.tag){
        // Get Main Results
        query = [this.$prismic.Predicates.at("document.tags", [this.$route.params.tag || '']),
                this.$prismic.Predicates.at('document.type', 'blog_post')]
      }
      else{
        query = this.$prismic.Predicates.at('document.type', 'blog_post')
      }
      this.$prismic.client.query(query,
        {
          pageSize : this.perPage,
          page: this.currentPage,
          orderings : '[document.first_publication_date desc]'
        },
      ).then((response) => {
        this.totalPostSize = response.total_results_size,
        this.results = response.results;
      });
    },
    handlePagination(page){
      this.currentPage = page;
      this.getContent();
    },
    filterTag(tag){
      this.currentPage = 1;
      this.$router.push({ name: 'blog', params: { tag } });
    },
  },
  metaInfo() {
    return {
      meta: [
        // Twitter Card
        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: document.title},
        {name: 'twitter:image', content: this.featuredPost.data.thumbnail.url},
        // Facebook OpenGraph
        {property: 'og:title', content: document.title},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  this.featuredPost.data.thumbnail.url},
      ]
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.posts{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.featured-post{
  margin-bottom: 40px;
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .thumbnail{
    width: 100%;
    height: 280px;
    background-size: cover;
    background-position: center center;
  }
  .details{
    padding: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h1{
      font-size: 28px;
      font-weight: bold;
    }
    span{
      font-weight: bold;
      letter-spacing: 8px;
      font-size: 10px;
      border: 1px solid;
      padding: 6px 4px 6px 12px;
      display: inline-block;
      text-align: center;
      margin-bottom: 8px;
    }
    p{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  @include sm{
    .thumbnail{
      height: 450px;
    }
  }
  @include lg{
    .thumbnail{
      width: 50%;
    }
    .details{
      width: 50%
    }
  }
}
.post{
  max-width: 800px;
  margin: 0 auto 16px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .thumbnail{
    width: 100%;
    max-width: 100%;
    background-size: cover;
    background-position: center center;
    min-height: 240px;
  }
  .details{
    width: 100%;
    padding: 12px;
    text-align: left;
    color: #fff;
    h1{
      font-size: 20px;
      font-weight: bold;
    }
    p{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .date{
      font-size: 14px;
      margin-bottom: 8px;
    }
    .tags{
      margin: 8px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  @include sm {
    .thumbnail{
      min-height: 360px;
    }
  }
  @include md {
    .thumbnail{
      width: 35%;
      max-width: 600px;
      min-height: 240px;
    }
    .details{
      width: 65%;
      padding: 0px 16px;
    }
  }
}
.tag{
  display: inline-block;
  font-size: 11px;
  margin: 0 8px 4px 0;
  cursor: pointer;
  border-radius: 16px;
  background: #555;
  padding: 2px 12px;
  color: #fff;
}

.pagination{
  display: flex;
  justify-content: center;
  /deep/ .b-pagination{
    align-items: center;
    .page-item{
      width: 50px;
      height: 50px;
      span{
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .page-link{
        margin: 0;
        width: inherit;
        height: inherit;
        background: transparent;
        border-color: transparent;
        color: #fff;
      }
      &.disabled{
        opacity: 0.5;
      }
      &.active{
        .page-link{
          font-weight: bold;
          border: 1px solid #fff;
          border-radius: 100%;
        }
      }
    }
  }
}
</style>
