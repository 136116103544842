<template lang="pug">
  .comic-container(v-if="document" class="px-4")
    .comic-header(class="d-flex align-items-center flex-wrap")
      .comic-info
        h2 {{ seriesTitle }}
        h1 {{ $prismic.richTextAsPlain(title) }}
        .date {{ date | moment("MMMM Do, YYYY") }}
        .social-media(class="mb-4")
          a(@click="twitterShare")
            twitterIcon
          a(@click="facebookShare")
            facebookIcon
          a(@click="copyToClipboard")
            linkIcon
          input#postLink(type="hidden" :value="window.location.href")
      .links
        a.link(v-if="action.enabled" v-for="action in actions" :href="action.url" target="_blank")
          template {{ action.label }}
    .pages-container(class="my-4")
      .page(v-for="page in pages" :class="{'spread': page.spread}")
        img(:src="page.url")
    .comic-footer(class="d-flex justify-content-center flex-wrap")
      div(class="d-flex mb-4")
        .nav-link(v-if="prevIssueTitle")
          strong Previous
          router-link( :to="{ name: 'comic', params: {comicId: prevIssue.uid, \
                  series: series.uid }}") {{ prevIssueTitle }}
        .nav-link(v-if="nextIssueTitle")
          strong Next
          router-link(:to="{ name: 'comic', params: {comicId: nextIssue.uid, \
                  series: series.uid }}") {{ nextIssueTitle }}

      .social-media(class="mb-4 w-100 justify-content-center")
        a(@click="twitterShare")
          twitterIcon
        a(@click="facebookShare")
          facebookIcon
        a(@click="copyToClipboard")
          linkIcon
      .message(class="w-100")
        p(class="text-align-center")  {{ endMessage }}
      .links(class="w-100 d-flex justify-content-center")
        a.link(v-if="action.enabled" v-for="action in actions" :href="action.url" target="_blank")
          template {{ action.label }}
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex';
import twitterIcon from '@/assets/twitter.svg';
import facebookIcon from '@/assets/facebook.svg';
import linkIcon from '@/assets/link.svg';


export default {
  data() {
    return {
      title: '',
      content: '',
      pages: [],
      document: null,
      docTitle: null,
      thumbnail: null,
      description: null,
      window
    };
  },
  components: {
    twitterIcon,
    facebookIcon,
    linkIcon,
  },
  async mounted() {
    this.init();
  },
  watch:{
    $route(newVal){
      if(newVal){
        this.init();
      }
    }
  },
  methods: {
    ...mapActions({
    }),
    async init(){
      this.document = await this.$prismic.client.getByUID('comic', this.$route.params.comicId);
      this.title = this.document.data.title;
      this.pages = this.document.data.pages;
      this.series = this.document.data.series;
      this.seriesTitle = this.document.data.series_title;
      this.actions = this.document.data.actions;
      this.date = this.document.data.published_date;
      this.endMessage = this.document.data.end_message;
      this.nextIssue = this.document.data.next_issue_link;
      this.nextIssueTitle = this.document.data.next_issue_title;
      this.prevIssue = this.document.data.previous_issue;
      this.prevIssueTitle = this.document.data.previous_issue_title;
      this.docTitle = `${this.$prismic.richTextAsPlain(this.title)} - neocomicart` || 'neocomicart';
      document.title = this.docTitle;
      this.thumbnail = this.document.data.thumbnail;
      this.description = this.document.data.description.substring(0,190).concat('...');
      gtag('config', 'G-M8SYXK0ST9', { page_path: this.$route.path });
      window.scrollTo(0, 0);
    },
    titleCase(string){
      let sentence = string.toLowerCase().split(" ");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
      return sentence.join(" ");
    },
    twitterShare () {
      window.open(
      `https://twitter.com/intent/tweet/?text=${window.location.href}`,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    },
    facebookShare() {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,'facebook-share-dialog','width=626,height=436');
      return false;
    },
    copyToClipboard() {
      /* Get the text field */
      var copyText = document.getElementById("postLink");
      copyText.setAttribute('type', 'text');
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      copyText.setAttribute('type', 'hidden');
    }
  },
  metaInfo() {
    return {
      meta: [
        // Twitter Card
        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: this.docTitle},
        {name: 'twitter:description', content: this.description},
        {name: 'twitter:image', content: this.thumbnail},
        // Facebook OpenGraph
        {property: 'og:title', content: this.docTitle},
        {property: 'og:type', content: 'article'},
        {property: 'og:image', content:  this.thumbnail},
        {property: 'og:description', content: this.description}
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.comic-container{
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  h1{
    font-size: 20px;
    font-weight: bold;
  }
  h2{
    font-size: 16px;
  }
}
.pages-container{
  .page{
    max-width: 900px;
    margin: 0 auto;
    &.spread{
      max-width: 1200px;
    }
  }
  img{
    width: 100%;
    pointer-events: none;
    margin-bottom: 12px;
  }
}
.comic-info{
  width: 100%;
  word-break: break-all;
  text-align: center;
  @include lg {
    width: 40%;
    text-align: left;
  }
}
.comic-header{
  .links{
    width: 100%;
    text-align: center;
    word-break: break-word;
    margin: 12px 0px;
    @include lg {
      margin-left: auto;
      width: 60%;
      text-align: right;
      .link{
        margin: 0px 8px;
      }
    }
  }
}
.comic-footer{
  margin: 60px 0px;
  text-align: center;
  .links{
    flex-wrap: wrap;
  }
}
.link{
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  background: #fff;
  color: #000;
  padding: 8px 12px;
  border-radius: 20px;
  margin: 0px 0px 12px;
  margin-left: 4px;
  transition: 0.3s;
  &:hover{
    text-decoration: none;
    background: #222;
    color: #fff;
  }
}
.social-media{
  margin: 8px 0px;
  display: flex;
  justify-content: center;
  a{
    cursor: pointer;
    margin: 0px 4px;
    width: 32px;
    height: 32px;
    background: #ddd;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      width: 14px;
      height: 14px;
      path{
        fill:#000;
      }
    }
  }
  @include lg {
    justify-content: flex-start;
  }
}
</style>
