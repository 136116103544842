<template lang="pug">
  div
    .lightbox(v-if="isLightboxOpen")
      .lightboxControls(v-if="lightboxItem")
        a(
          v-if="lightboxItem.external_link.url"
          :href="lightboxItem.external_link.url"
          target='_blank')
          | {{ lightboxItem.button_text }}
        router-link(v-if="lightboxItem.button_link.uid" :to="{ name: 'comic', params: {comicId: \
            lightboxItem.button_link.uid, series: lightboxItem.series.uid }}" target='_blank')
            | {{ lightboxItem.button_text }}
        close-icon(@click="closeLightbox")
      .lightboxImage(:style="{ backgroundImage:`url(${lightboxItem.image_url}` }")
      //- img(:src="lightboxItem.image_url")
    .gallery(v-if="gallery")
      .image-container(@click="openLightbox(item)"
        v-for="item in gallery" :class="item.size")
        .overlay
            h4 {{ item.title }}
        img(:src="item.image_url")
</template>

<script>
/* eslint-disable */
import PrismicDom from 'prismic-dom';
import spinnerIcon from '@/assets/asterisk-light.svg';
import closeIcon from '@/assets/times.svg';

export default {
  name: 'App',
  components: {
    spinnerIcon,
    closeIcon,
  },
  data() {
    return {
      gallery: null,
      homepage: null,
      isLightboxOpen: false,
      lightboxItem: null,
      metaDescription: null,
      metaImage: null,
    };
  },
  created () {
    this.getContent();
  },
  methods: {
    getContent () {
      this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'homepage'),
      ).then((response) => {
        this.homepage = response.results[0];
        this.gallery= this.homepage.data.gallery;
        this.metaDescription = this.homepage.data.meta_description;
        this.metaImage = this.gallery[0].image_url;
      });
    },
    openLightbox(item){
      this.isLightboxOpen = true;
      this.lightboxItem = item;
    },
    closeLightbox(){
      this.isLightboxOpen= false;
      this.lightboxItem = null;
    },
  },
  metaInfo() {
    return {
      meta: [
        // Twitter Card
        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: document.title},
        {name: 'twitter:description', content: this.metaDescription},
        {name: 'twitter:image', content: 'https://www.neocomicart.com/favicon.ico'},
        // Facebook OpenGraph
        {property: 'og:title', content: document.title},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content:  'https://www.neocomicart.com/favicon.ico'},
        {property: 'og:description', content: this.metaDescription}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
.gallery{
  // border: 1px solid red;
  display: flex;
  flex-wrap: wrap;
  padding: 12px 12px;
  align-items: center;
}
.lightbox{
  .lightboxImage{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  >.lightboxControls{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 24px;
    width: 100%;
    z-index: 100;
    background-image:linear-gradient( #000, rgba(0,0,0,0) 100%);
    svg{
      cursor: pointer;
      margin-left: 12px;
      width: 30px;
      height: 30px;
      path{
        fill: #fff;
      }
    }
    a{
      font-size: 14px;
      border: 1px solid #fff;
      padding: 8px 16px;
      transition: 0.3s;
      text-decoration: none;
      &:hover{
        background: #fff;
        color: #000;
      }
    }
  }
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    border: 1px solid red;
    height: 100%;
    max-width: 1200px;
    width: auto;
  }
}
.image-container{
  width: 100%;
  padding: 0 8px;
  margin-bottom: 16px;
  max-height: 600px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .overlay{
    z-index: 20;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    transition: 0.3s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    h4{
      font-size: 18px;
      font-weight: bold;
    }
  }
  &:hover{
    .overlay{
      opacity: 0.6;
    }
  }
  &.medium{
    width: 100%;
  }
  img{
    width: 100%;
  }
  @include sm{
    width: 50%;
    &.medium{
      width: 100%;
    }
  }
  @include lg{
    width: 33.3%;
    &.medium{
      width: 66.6%;
    }
  }
  @include xl{
    width: 25%;
    &.medium{
      width: 50%;
    }
  }
  @include xxl{
    width: 16.6%;
    &.medium{
      width: 33.2%;
    }
  }
  // .image{
  //   min-height: 600px;
  //   width: 100%;
  //   background-size: cover;
  //   background-position: center center;
  // }
}
</style>
