<template lang="pug">
  <div id="app" class="d-flex flex-column">
    .top-bar(@click="" v-if="isTopBarOn" class="w-100")
      a(href="http://eepurl.com/hhCPzr" target="_blank") Sign up for the newsletter to get updates and previews :)
      close-icon(@click="closeTopBar")
    Header
    <router-view />
  </div>
</template>

<script>
/* eslint-disable */
import Header from '@/components/Header.vue';
import { mapActions } from 'vuex';
import closeIcon from '@/assets/times.svg';

export default {
  name: 'App',
  components: {
    Header,
    closeIcon,
  },
  data() {
    return {
      results: [],
      isTopBarOn: true,
    };
  },
  watch: {
    $route(to, from) {
      this.updateAnalytics(to)
    }
  },
  async mounted () {
    this.updateAnalytics(this.$route);
    // Get Featured Post
    const featuredQuery = await this.$prismic.client.query(
      this.$prismic.Predicates.at('document.type', 'blog_page'),
    )
    const featuredPostData = featuredQuery.results[0].data.featured_post;
    this.featuredPost = await this.$prismic.client.getByID(featuredPostData.id)
    this.setFeaturedPost(this.featuredPost);
    this.$prismic.client.query(
      this.$prismic.Predicates.at('document.type', 'blog_post')).then((response) => {
        this.setTotalPostSize(response.total_results_size);
      });
  },
  methods: {
    ...mapActions({
      setTotalPostSize: 'setTotalPostSize',
      setFeaturedPost: 'setFeaturedPost',
    }),
    updateAnalytics(route){
      if(route.name === 'posts' || route.name === 'comic'){
        return false;
      }
      else{
        document.title = `${route.meta.title} - neocomicart` || 'neocomicart'
        gtag('config', 'G-M8SYXK0ST9', {'page_path': route.path});
      }
    },
    closeTopBar(){
      this.isTopBarOn = false;
    }
  },
}
</script>

<style lang="scss">
body{
  background: #000;
  color: #fff;
  h1,h2,h3,h4,h5,h6,p,a,span{
    color: #fff;
  }
}
#app {
  font-family: 'Averta', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

.top-bar{
  background: #7a2424;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 10px 48px;
  z-index: 12;
  a{
    font-size: 14px;
  }
  svg{
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    height: 20px;
    width: 20px;
    margin: 0px 8px;
    cursor: pointer;
    path{
      fill: #fff;
    }
  }
}

a{
  // margin: 24px;
  text-decoration: none;
  color: #000;
  display: block;
  justify-content: center;
  &:hover{
    text-decoration: underline;
    color: #fff;
  }
  p{
    display: block;
    margin: 0;
  }
}
</style>
