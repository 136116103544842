<template lang="pug">
  div(v-if="!aboutPage" class="loading-overlay")
    <spinner-icon />
  div(v-else)
    .about
      .profile(class="mb-3")
        .image(:style="`backgroundImage:url('${aboutPage.data.profile.url}')`")
      .logo
        img(:src="aboutPage.data.logo.url")
      .content(class="mb-3")
        div(v-html="content")
      .links
        a(v-for="link in aboutPage.data.links" :href="link.url" target="_blank")
          div(v-html="link.svg") {{link.svg}}

</template>

<script>
/* eslint-disable */
import PrismicDom from 'prismic-dom';
import spinnerIcon from '@/assets/asterisk-light.svg';
import twitterIcon from '@/assets/twitter.svg';
import instagramIcon from '@/assets/instagram.svg';
import closeIcon from '@/assets/times.svg';

export default {
  name: 'App',
  components: {
    spinnerIcon,
    closeIcon,
    twitterIcon,
    instagramIcon,
  },
  data() {
    return {
      gallery: null,
      aboutPage: null,
      isLightboxOpen: false,
      lightboxItem: null,
      content: '',
    };
  },
  created () {
    this.getContent();
  },
  methods: {
    async getContent () {
      const response = await this.$prismic.client.query(
        this.$prismic.Predicates.at('document.type', 'about_page'),
      )
      this.aboutPage = response.results[0];
      this.content = PrismicDom.RichText.asHtml(this.aboutPage.data.content);
    },
  },
}
</script>

<style lang="scss" scoped>
.about{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0px 20px;
}
.profile{
  width: 100%;
  display: flex;
  justify-content: center;
  .image{
    width: 200px;
    height: 200px;
    border-radius: 100%;
    background-size: cover;
    background-position: center center;
  }
}
.content{
  width: 100%;
  > div{
    max-width: 600px;
    margin: 0 auto;
  }
}
.links{
  width: 100%;
  display: flex;
  justify-content: center;
  a{
    /deep/ svg{
      color: #fff;
      height: 28px;
      width: 28px;
      margin: 0px 8px;
      path{
        fill: #fff;
      }
    }
  }
}
.logo{
  margin: 12px 0;
  img{
    width: 80px;
    height: 80px;
  }
}
</style>
