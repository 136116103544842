/*eslint-disable*/
import Vue from 'vue';
import Router from 'vue-router';
import BlogPost from '@/components/BlogPost.vue';
import Blog from '@/views/Blog.vue';
import Comics from '@/views/Comics.vue';
import Comic from '@/views/Comic.vue';
import About from '@/views/About.vue';
import Home from '@/views/Home.vue';
import Series from '@/views/Series.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'Home'
      }
    },
    {
      path: '/blog/:tag?',
      name: 'blog',
      component: Blog,
      meta: {
        title: 'Blog'
      }
    },
    {
      path: '/blog/post/:uid',
      name: 'posts',
      component: BlogPost,
      meta: {
        title: route => `${route.params.title}`,
      }
    },
    {
      path: '/comics',
      name: 'comics',
      component: Comics,
      meta: {
        title: 'Comics'
      }
    },
    {
      path: '/comics/series',
      name: 'series',
      component: Series,
      meta: {
        title: 'Comics'
      }
    },
    {
      path: '/comics/series/:series/:comicId',
      name: 'comic',
      component: Comic,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        title: 'About',
      }
    },
  ]
});

export default router;