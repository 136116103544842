/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    comics: [],
    totalPostSize: null,
    featuredPost: null,
  },
  getters: {
    getAllComics(state) {
      return state.comics;
    },
    totalPostSize(state){
      return state.totalPostSize;
    },
    featuredPost(state){
      return state.featuredPost;
    },
    // getSingleComic(state, uid) {
    //   return state.comics.find(comic => comic.uid === uid);
    // }
  },
  mutations: {
    setComics(state, comics) {
      state.comics = comics;
    },
    setTotalPostSize(state, size){
      state.totalPostSize = size;
    },
    setFeaturedPost(state, post) {
      state.featuredPost = post;
    },
    // setFilteredSurveys(state, filteredSurveys) {
    //   state.filteredSurveys = filteredSurveys;
    //   state.availableSurveys = filteredSurveys.filter((survey) => !survey.deleted);
    // },
  },
  actions: {
    setComics(ctx, comics) {
      ctx.commit("setComics", comics);
    },
    getSingleComic(ctx, uid) {
      return ctx.state.comics.find(comic=>{ return comic.uid === uid});
    },
    setTotalPostSize(ctx, size){
      ctx.commit('setTotalPostSize', size);
    },
    setFeaturedPost(ctx, post) {
      ctx.commit('setFeaturedPost', post);
    }

    // async selectSurveyHistory(ctx, historyItem) {
    //   try {
    //     ctx.commit('setSelectedSurveyHistory', historyItem);
    //     return historyItem;
    //   } catch {
    //     return null;
    //   }
    // },
  },
  modules: {}
});
