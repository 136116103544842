<template lang="pug">
  div(v-if="!content" class="loading-overlay")
    <spinner-icon />
  .post-container(v-else)
    div(class="mb-5")
      img(:src="thumbnail" class="w-100")
      .date(class="mt-4 mb-2") {{ date |  moment("MMMM Do, YYYY") }}
      h1(class="px-2 mb-3") {{ $prismic.richTextAsPlain(title) }}
      .social-media(class="mb-4")
        a(@click="twitterShare")
          twitterIcon
        a(@click="facebookShare")
          facebookIcon
        a(@click="copyToClipboard")
          linkIcon
        input#postLink(type="hidden" :value="postLink")
      .tags
        .tag(@click="filterTag(tag)" v-for="tag in tags") {{ tag }}
    .post(v-html="content" class="my-3 px-3")
    .social-media(class="mb-3")
        a(@click="twitterShare")
          twitterIcon
        a(@click="facebookShare")
          facebookIcon
        a(@click="copyToClipboard")
          linkIcon
        input#postLink(type="hidden" :value="window.location.href")
    .nav-posts(class="d-flex")
      div(v-if="prevpost")
        router-link(:to="{ name: 'posts', \
          params: { uid: prevpost.uid, title: $prismic.richTextAsPlain(prevpost.data.title) }}")
          span Previous
          .image(:style="`backgroundImage: url('${prevpost.data.thumbnail.url}')`")
          p {{$prismic.richTextAsPlain(prevpost.data.title)}}
      div(v-if="nextpost" class="ml-auto")
        router-link(:to="{ name: 'posts', \
          params: { uid: nextpost.uid, title: $prismic.richTextAsPlain(nextpost.data.title) }}")
          span Next
          .image(:style="`backgroundImage: url('${nextpost.data.thumbnail.url}')`")
          p {{$prismic.richTextAsPlain(nextpost.data.title)}}
</template>

<script>
/*eslint-disable*/
import PrismicDom from 'prismic-dom';
import spinnerIcon from '@/assets/asterisk-light.svg';
import twitterIcon from '@/assets/twitter.svg';
import facebookIcon from '@/assets/facebook.svg';
import linkIcon from '@/assets/link.svg';

export default {
  data() {
    return {
      title: '',
      content: '',
      prevpost: null,
      nextpost: null,
      contentPreview: null,
      thumbnail: null,
      docTitle: null,
      window
    }
  },
    components: {
    spinnerIcon,
    twitterIcon,
    facebookIcon,
    linkIcon,
  },
  computed: {
    postLink() {
      return (window.location.origin +'/' + window.location.hash);
    },
  },
  watch:{
    $route(newVal){
      this.init();
    }
  },
  async mounted() {
    this.init();
  },
  methods:{
    async init(){
      const post = await this.$prismic.client.getByUID('blog_post', this.$route.params.uid)
      this.title = post.data.title;
      this.thumbnail= post.data.thumbnail.url;
      this.content = PrismicDom.RichText.asHtml(post.data.content);
      this.contentPreview = this.$prismic.richTextAsPlain(post.data.content).substring(0,190).concat('...');
      this.date = post.first_publication_date;
      this.tags = post.tags;

      this.prevpost = (await this.$prismic.client.query(this.$prismic.Predicates.at('document.type', 'blog_post'),
        { pageSize : 1 , after : `${post.id}`, orderings: '[document.first_publication_date desc]'})).results[0]

      this.nextpost = (await this.$prismic.client.query(this.$prismic.Predicates.at('document.type', 'blog_post'),
        { pageSize : 1 , after : `${post.id}`, orderings: '[document.first_publication_date]'})).results[0]
      this.docTitle = `${this.$prismic.richTextAsPlain(this.title)} - neocomicart` || 'neocomicart';
      document.title = this.docTitle
      gtag('config', 'G-M8SYXK0ST9', { page_path: this.$route.path });
      window.scrollTo(0, 0);
    },
    filterTag(tag){
      this.$router.push({ name: 'blog', params: { tag } });
    },
    twitterShare () {
      window.open(
      `https://twitter.com/intent/tweet/?text=${window.location.href}`,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
    },
    facebookShare() {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,'facebook-share-dialog','width=626,height=436');
      return false;
    },
    copyToClipboard() {
      /* Get the text field */
      var copyText = document.getElementById("postLink");
      copyText.setAttribute('type', 'text');
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      copyText.setAttribute('type', 'hidden');
    }
  },
  metaInfo() {
    return {
      meta: [
        // Twitter Card
        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: this.docTitle},
        {name: 'twitter:description', content: this.contentPreview},
        {name: 'twitter:image', content: this.thumbnail},
        // Facebook OpenGraph
        {property: 'og:title', content: this.docTitle},
        {property: 'og:type', content: 'article'},
        {property: 'og:image', content:  this.thumbnail},
        {property: 'og:description', content: this.contentPreview}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.post-container{
  color: #fff;
  width: 100%;
  h1{
    font-size: 24px;
    font-weight: bold;
  }
  max-width: 800px;
  margin: 0 auto;
  img{
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  .post{
    text-align: left;
    border-bottom: 1px solid #fff;
    /deep/ .block-img{
      text-align: center;
      img{
        max-width: 600px;
        width: 100%;
      }
    }
    /deep/ iframe{
      width: 100%;
      height: 400px;
    }
    /deep/ a{
      text-decoration: underline;
      display: inline;
    }
    /deep/ pre{
      color: #fff;
      white-space: pre-wrap;
    }
  }
}
.social-media{
  display: flex;
  justify-content: center;
  a{
    cursor: pointer;
    margin: 0px 4px;
    width: 32px;
    height: 32px;
    background: #ddd;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      width: 14px;
      height: 14px;
      path{
        fill:#000;
      }
    }
  }
}
.tags{
  margin: 8px 0px;
  display: flex;
  justify-content: center;
}
.tag{
  display: inline-block;
  font-size: 11px;
  margin: 0 8px 4px 0;
  cursor: pointer;
  border-radius: 16px;
  background: #555;
  padding: 2px 12px;
  color: #fff;
}
.nav-posts{
  border-top: 1px solid #fff;
  padding: 24px 0;
  width: 100%;
  flex-wrap: wrap;
  >div{
    margin-bottom: 12px;
    width: 100%;
    // border: 1px solid red;
    .image{
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 240px;
      margin-bottom: 4px;
    }
    p{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    span{
      font-weight: bold;
      display: inline-block;
      margin-bottom: 8px;
    }
  }
  @include sm{
    >div{
      margin-bottom: 0px;
      width: 40%;
      max-width: 240px;
      .image{
        height: 160px;
      }
    }
  }
}

</style>