<template lang="pug">
  .navbar(class="d-flex flex-wrap align-items-center")
    div(class="my-2")
      router-link.logo.logo--mobile(:to="{ name: 'home'}")
        strong EDISON NEO
    .links(class="links d-flex")
      router-link(:to="{ name: 'series'}") Comics
      //- router-link(:to="{ name: 'blog'}") Blog
      router-link.logo.logo--desktop(:to="{ name: 'home'}")
        strong EDISON NEO
      router-link(:to="{ name: 'about'}") About
      a(href='https://ko-fi.com/neocomicart/shop' target='_blank') Shop
    </div>
  </div>
</template>
<script>
export default {

};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
a{
  text-decoration: none;
}
.navbar{
  justify-content: center;
  padding: 0px 20px;
  margin: 80px 0px 40px;
  .links{
    margin-left: 24px;
    align-items: center;
    strong{
      font-size: 32px;
      font-weight: bold;
    }
    a{
      text-decoration: none;
      margin: 0 12px;
      transition: 0.3s;
      &.router-link-active{
        font-weight: bold;
        // text-decoration: underline;
      }
      &:hover{
        font-weight: bold;
      }
    }
  }
}

.logo{
  &--desktop{
    display: none;
  }
  &--mobile{
    display: block;
    strong{
      font-size: 24px;
    }
  }
  @include sm{
    &--desktop{
      display: block;
    }
    &--mobile{
      display: none;
    }
  }
}

</style>
